<template>

  <section class="d-block d-md-none" v-if="banners">

    <div class="d-flex flex-column justify-content-center align-items-center" style="gap: 15px;">
      <article v-if="banners.first_bannerMobile && banners.first_bannerMobile.picture">
        <DynamicLinker :item="banners.first_bannerMobile">
          <div class="w-100">
            <img class="w-100" :src="banners.first_bannerMobile.picture" />
          </div>
        </DynamicLinker>
      </article>
      <article v-if="banners.second_bannerMobile && banners.second_bannerMobile.picture">
        <DynamicLinker :item="banners.second_bannerMobile">
          <div class="w-100">
            <img class="w-100" :src="banners.second_bannerMobile.picture" />
          </div>
        </DynamicLinker>
      </article>
      <article v-if="banners.third_bannerMobile && banners.third_bannerMobile.picture">
        <DynamicLinker :item="banners.third_bannerMobile">
          <div class="w-100">
            <img class="w-100" :src="banners.third_bannerMobile.picture" />
          </div>
        </DynamicLinker>
      </article>
      <article v-if="banners.fourth_bannerMobile && banners.fourth_bannerMobile.picture">
        <DynamicLinker :item="banners.fourth_bannerMobile">
          <div class="w-100">
            <img class="w-100" :src="banners.fourth_bannerMobile.picture" />
          </div>
        </DynamicLinker>
      </article>
      <article v-if="banners.fifth_bannerMobile && banners.fifth_bannerMobile.picture">
        <DynamicLinker :item="banners.fifth_bannerMobile">
          <div class="w-100">
            <img class="w-100" :src="banners.fifth_bannerMobile.picture" />
          </div>
        </DynamicLinker>
      </article>

    </div>

  </section>
</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";

import img1 from "../../../assets/images/aftab.webp";
import img2 from "../../../assets/images/joosh.webp";
import img3 from "../../../assets/images/bodycare.webp";
import img4 from "../../../assets/images/lak.webp";
import img5 from "../../../assets/images/mask.webp";
export default {
  data() {
    return {
      special: [
        {
          id: 1,
          title: "ضد آفتاب",
          url: img1,
          link: "191",
        },
        {
          id: 2,
          title: "ضد جوش",
          url: img2,
          link: "139",
        },
        {
          id: 3,
          title: "مراقبت بدن",
          url: img3,
          link: "81",
        },
        {
          id: 4,
          title: "ضد لک",
          url: img4,
          link: "142",
        },
        {
          id: 5,
          title: "ماسک",
          url: img5,
          link: "187",
        },
      ],
    };
  },
  components: {
    DynamicLinker,

  },
  computed: {
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return advertise;
      } else {
        return null;
      }
    },
    // special_categories() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return null;
    //   } else {
    //     return this.$store.getters["front/getHomeData"].special_categories;
    //   }
    // },
  },
};
</script>

<style></style>
